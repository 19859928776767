<template>
  <div class="activity_item" @click="$emit('details')">
    <div class="activity_item__container">
      <!-- <div class="activity_item__icon">
        <img :src="avatar" alt="Avatar">
      </div> -->
      <div>
        <!-- <div class="activity_item__title" style="word-wrap: break-word">
          {{ activeTitle }}
        </div> -->
        <div
          class="activity_item__title"
          style="
            word-wrap: break-word;
            width: 300px;
            display: flex;
            justify-content: space-between;
          "
        >
          <span class="inline-block">{{ activeTitle }}</span>
          <span class="inline-block"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fill="#01a4c0"
                d="M12.6 12L8 7.4L9.4 6l6 6l-6 6L8 16.6l4.6-4.6Z"
              /></svg
          ></span>
        </div>
        <div class="activity_item__description">
          {{ description }}
        </div>
        <div
          class="font-bold reward"
          :style="{
            color:
              stages.length === 1 && stages[0].status !== 'pending_reward'
                ? stages[0].status === 'completed'
                  ? '#56B897'
                  : stages[0].status === 'failed'
                  ? '#FE8585'
                  : stages[0].status === 'expired'
                  ? '#FE8585'
                  : stages[0].status === 'in_progress'
                  ? '#9E9E9E'
                  : '9E9E9E'
                : '9E9E9E',
          }"
        >
          <div v-if="type == 'wishlist'">Product Reward</div>
          <div v-if="type == 'points'">
            {{ activeSumPoints }}
            Points
          </div>
          <div v-if="type == 'money'">+ &pound;{{ activeSum }}</div>
        </div>
        <!-- <div
          class="font-bold reward"
          v-if="type == 'points'"
        >
          <div>
            {{ activeSumPoints}}
            Points
          </div>
        </div>
        <div
          class="font-bold reward"
          v-if="type == 'wishlist' "
        >
          <div>Product Reward</div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import config from "../config";
import { mapActions } from "vuex";
export default {
  components: {
    // AddButton,
    // AppModal,
    // AppMissionDetails,
    // countdown,
  },
  props: {
    mission: {
      type: Object,
    },
    color: {
      type: String,
      default: "",
    },
    avatar: {
      type: String,
      default: "",
    },
    stages: {
      type: Array,
      default: () => [],
    },
    spinner: {
      type: Boolean,
      default: false,
    },
    pending: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    mission_type: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    activeMissionType: {
      type: String,
      default: "",
    },
    activeTitle: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    activeInstructions: {
      type: String,
      default: "",
    },
    activeSum: {
      type: String,
      default: "",
    },
    balance: {
      type: String,
      default: "",
    },
    activeSumPoints: {
      type: String,
      default: "",
    },
    timeleft: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showMiniModal: false,
      isCardSelected: false,
      showMission: {},
      timeleftCopy: 0,
      message: "Your kid will receive",
    };
  },
  computed: {
    timerTime() {
      return this.timeleft;
    },
    currentDate() {
      var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      return [day, month, year].join("-");
    },
    //            headingString() {
    //
    //                if (this.timeleftCopy > 0) {
    //                    let days = Math.floor(this.timeleftCopy/ (3600*24));
    //                    let hours = Math.floor(this.timeleftCopy / 60 / 60);
    //                    let minutes = Math.floor(this.timeleftCopy / 60) - (hours * 60);
    //                    let seconds = Math.floor(this.timeleftCopy % 60);
    //                    return hours > 0 ? `Due in ${days} days ${hours}h ${minutes}m ${seconds}s` : `Due in ${minutes}m ${seconds}s`;
    //                } else {
    //                    return this.status;
    //                }
    //            },
    headingString() {
      // if (this.status === "in progress") {
      if (this.timeleftCopy > 0) {
        // var days = Math.floor(this.timeleftCopy / (3600 * 24));
        // this.timeleftCopy -= days * 3600 * 24;
        // var hours = Math.floor(this.timeleftCopy / 3600);
        // this.timeleftCopy -= hours * 3600;
        // var minutes = Math.floor(this.timeleftCopy / 60);
        // this.timeleftCopy -= minutes * 60;
        // console.log(
        //   days +
        //     " days, " +
        //     hrs +
        //     " Hrs, " +
        //     mnts +
        //     " Minutes, " +
        //     seconds +
        //     " Seconds"
        // );

        // let hours = Math.floor(this.timeleftCopy / 60 / 60);
        // let minutes = Math.floor(this.timeleftCopy / 60) - hours * 60;
        // let seconds = Math.floor(this.timeleftCopy % 60);

        var numdays = Math.floor(this.timeleftCopy / 86400);
        var numhours = Math.floor((this.timeleftCopy % 86400) / 3600);
        var numminutes = Math.floor(((this.timeleftCopy % 86400) % 3600) / 60);
        var numseconds = ((this.timeleftCopy % 86400) % 3600) % 60;

        // return (
        //   numdays +
        //   " days " +
        //   numhours +
        //   " hours " +
        //   numminutes +
        //   " minutes " +
        //   numseconds +
        //   " seconds"
        // );

        return numdays > 0
          ? `Due in ${numdays}d ${numhours}h ${numminutes}m ${numseconds}s`
          : `Due in ${numhours}h ${numminutes}m ${numseconds}s`;
      } else {
        return "In Progress";
      }
      //   } else {
      //     return "Failed";
      //   }
      // } else if (this.status === "pending reward") return "Pending reward";
      // else if (this.status === "completed") return "Completed";
      // else if (this.status === "expired") return "Expired";
      // else return "Failed";
    },
  },
  methods: {
    ...mapActions(["pastMissionConfirm"]),
    countDownTimer() {
      if (this.timeleftCopy > 0) {
        setTimeout(() => {
          (this.timeleftCopy -= 1), this.countDownTimer();
        }, 1000);
      }
    },
    monthToName(str) {
      var monthShortNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var date = str.split("-").reverse().join("-");
      var parts = date.split("-");
      var getMonth = parts[1];
      var getDay = parts[2];
      var t = new Date(`${parts[0]}/${getMonth}/${getDay}`);
      return t.getDate() + " " + monthShortNames[t.getMonth()];
    },
    dateToDayName(str) {
      var dayName = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      var date = str.split("-").reverse().join("-");
      return dayName[new Date(date).getDay()];
    },
    convertTime(time) {
      var hourEnd = time.indexOf(":");
      var H = +time.substr(0, hourEnd);
      var h = H % 12 || 12;
      var ampm = H < 12 ? "am" : "pm";
      return h + time.substr(hourEnd, 3) + ampm;
    },
    resolveImage(src) {
      return config.HOST + src;
    },
    confirmStage(id) {
      // let missionSum = parseInt(sum);
      // if (type == "money") {
      // if (this.balance > 0 && this.balance >= missionSum) {
      this.pastMissionConfirm(id);
      this.isCardSelected = false;
      // } else {
      //   this.$vs.notification({
      //     color: "danger",
      //     position: "top-left",
      //     duration: 6000,
      //     progress: "auto",
      //     title: "Oops..",
      //     text:
      //       "You do not have enough money to spend on this mission. Please add money to your account!",
      //   });
      // }
      // } else {
      //   this.pastMissionConfirm(id);
      //   this.isCardSelected = false;
      // }
    },
    selectedCard() {
      // console.log(event);
      this.isCardSelected = !this.isCardSelected;
      // console.log(this.isCardSelected);
    },
    openMiniShow() {
      // this.showMiniModal = true;
      // this.showMission.name = mission.name;
      // this.showMission.description = mission.description;
      // this.showMission.sum = mission.sum;
      this.$store.state.pastMissionsModal = true;
    },
    closeMiniShow() {
      // this.showMiniModal = false;
      // this.showMission.name = "";
      // this.showMission.description = "";
      // this.showMission.sum = "";
      this.$store.state.pastMissionsModal = false;
    },
  },
  created() {
    // console.log(this.mission)
    // console.log(this.pending);
    this.timeleftCopy = this.timeleft;
    this.countDownTimer();
    // console.log("timeleft-------------------------------------");
    // console.log(this.timeleftCopy);
    // console.log(this.timeleft);
    // console.log("timeleft-------------------------------------");
  },
};
</script>

<style lang="scss" scoped>
.stage-bullet {
  width: 1.3rem;
  height: 1.3rem;
  border-width: 0.125rem;
  border-radius: 9999px;
  --border-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--border-opacity));
  border-style: solid;
  --text-opacity: 1;
  color: #fff;
  margin-left: -0.5rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 10px;
  --bg-opacity: 1;
  background-color: var(--main);
}

.card {
  background: #fdfdff;
  // width: 250px;
  position: relative;
  width: 100%;
  height: 360px;
  display: block;
  border-radius: 50px;
  border-top-right-radius: 10px;
  // border-top-left-radius: 10px;
  clear: both;
  min-height: 320px;
  /*  box-shadow: 0px 100px 137px rgba(0, 0, 0, 0.13), 0px 12.5216px 63.5151px rgba(0, 0, 0, 0.0560287); */
  font-weight: 200;
  color: #7a615a;
  // box-shadow: 0px 32.3097px 59.2344px rgba(0, 25, 88, 0.25),
  //   inset 0px 5.07741px 10.7699px rgba(255, 255, 255, 0.75),
  //   inset 0px 5.5398px 26.9247px rgba(18, 127, 183, 0.29);
  // box-shadow: 0px 51px 80px rgba(0, 0, 0, 0.045),
  //   0px 6.38599px 10.0172px rgba(0, 0, 0, 0.09);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.01);
  transition: all 300ms ease-in-out;
}

.mask {
  overflow: hidden;
  // width: 250px;
  width: 100%;
  height: 360px;
  border-radius: 50px;
  border-top-right-radius: 10px;
  // border-top-left-radius: 10px;
  position: relative;
  display: flex;
  justify-content: center;
}

/* .mask:hover{
            transform: translateY(-100px);
          } */
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // width: 250px;
  width: 100%;
  height: 300px;
  position: relative;
  border-radius: 50px;
  // border-top-left-radius: 10px;
  transition: all 300ms ease-in-out;
  z-index: 999;
  padding: 20px;
  text-align: center;
}

.content:before {
  content: "";
  background-image: url("../assets/images/cardbg2.png");
  position: absolute;
  top: -150px;
  left: 0;
  width: 100%;
  height: 200%;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -11;
  // box-shadow: inset 0px -8.07741px 10.7699px rgba(255, 255, 255, 0.75),
  //   inset 0px -21.5398px 26.9247px rgba(18, 127, 183, 0.29);
}

/* .content:hover {
            transform: translateY(-100px);
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }  */

.content img {
  width: 100px;
  margin-top: -30px;
}

.content h1 {
  font-size: 20px;
  margin: 20px 0 5px 0;
  font-weight: 200;
}

.content p {
  margin: 0;
  // font-family: "Roboto", sans-serif;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.content h2 {
  color: #2ccbe8;
  font-size: 30px;
  margin-top: 25px;
}

.extra {
  // width: 85%;
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(25px, 1fr));
  // grid-gap: 10px;
  // grid-row-gap: 20px;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 320px;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  transition: all 300ms ease-in-out;
  z-index: 999;
}

.bullet {
  background: #b0b7cd;
  width: 25px;
  height: 25px;
  z-index: 10;
  border-radius: 50%;
  box-shadow: 0px 3.90625px 3.90625px rgba(255, 255, 255, 0.8),
    inset 0px 0px 3.8125px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
}

// .bullet:nth-child(1) {
//   box-shadow: none;
//   background: none;
//   font-size: 20px;
//   cursor: pointer;
// }

.bl-green {
  background: #2ce83f;
}

.bl-red {
  background: #ff0606;
}

.bl-orange {
  background: orange;
}

#cardMore {
  display: none;
}

.stage {
  background: #2ccbe8;
  position: absolute;
  top: 0;
  left: 0;
  width: 70px;
  height: 50px;
  border-radius: 100px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  // box-shadow: inset 0px -3.07741px 10.7699px rgba(255, 255, 255, 0.75),
  //   inset 0px -21.5398px 6.9247px rgba(18, 127, 183, 0.29);
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 32.81%,
      rgba(0, 0, 0, 0.02) 100%
    ),
    linear-gradient(180deg, #ffda7d 0%, #e6b41b 100%);
  background-blend-mode: soft-light, normal;
}

.cardSelected {
  transform: translateY(-50px);
}

.reactivateMission {
  //background: #7A615A;
  color: #fff;
  position: absolute;
  //background-image: url('../assets/images/main_mission_background.jpg');
  background-size: cover;
  background-position: bottom;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  display: flex;
  //padding: 10px 40px;
  justify-content: center;
  align-items: center;
  // font-family: "Roboto", sans-serif;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
}

.reactivateMission span:first-child {
  border-right: 1px solid rgb(122, 96, 90);
}

.reactivateMission span {
  padding: 5px 20px;
  text-align: center;
  width: 100%;
}

.mission_butttons_holder {
  position: absolute;
  top: -20px;
  left: 50%;
  display: flex;
  -webkit-transform: translate(-50%);
  -moz-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -o-transform: translate(-50%);
  transform: translate(-50%);
}

.missionCard {
  background: #ffffff;
  //background: rgb(229, 229, 229) !important;
  border: 3px dashed #e5e5e5;
  box-sizing: border-box;
  border-radius: 40px;
  width: 248px;
  height: 267px;
  //display: inherit;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 25px;
}

.appAdd {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
  border: 3px dashed #e5e5e5;
  box-sizing: border-box;
  border-radius: 40px;
  width: 248px;
  height: 267px;
  // margin-left: 50px;

  &-blue > .appAdd__text {
    margin-bottom: 30px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #36d5f2;
  }
  &-red > .appAdd__text {
    margin-bottom: 30px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #ff4f79;
  }
  &-yellow > .appAdd__text {
    margin-bottom: 30px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #ffd44f;
  }
}

.appAdd__btn {
  margin: 0 auto;
}

.appAdd__title {
  margin-top: -150px;
}

.appAdd__title__active {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  //color: #FF4F79;
  display: inline-table;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  padding: 0 15px;
  margin: 0 auto;
  background: #ff4f79;
  //margin-bottom: -10px;
}

.appAdd__mission__sum__active {
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 24px;
  color: #ff4f79;
  margin: 0 auto;
  padding-bottom: 20px;
}

.appAdd__delete {
  position: absolute;
  background-color: #ff4f79;
  top: 5px;
  right: 5px;
  width: 25px;
  height: 25px;
  border-radius: 10px;
  background-image: url("../assets/images/cancel.png");
  background-position: center;
  background-size: 15px;
  background-repeat: no-repeat;
  //margin-left: 93%;
  //margin-top: -140px;
  cursor: pointer;
  z-index: 1000;
}

.appAdd__edit {
  position: absolute;
  background-color: #7a615a;
  top: 5px;
  right: 30px;
  width: 25px;
  height: 25px;
  margin: 0 5px;
  border-radius: 10px;
  background-image: url("../assets/images/edit.svg");
  background-position: center;
  background-size: 11px;
  background-repeat: no-repeat;
  //margin-left: 93%;
  //margin-top: -140px;
  cursor: pointer;
  z-index: 1000;
}

.appAdd__view {
  //position: absolute;
  background-color: rgb(255, 223, 119);
  //top: 35px;
  //right: 0;
  width: 35px;
  height: 35px;
  border-radius: 12px;
  background-image: url("../assets/images/eye.svg");
  background-position: center;
  background-size: 20px;
  background-repeat: no-repeat;
  //margin-left: 93%;
  //margin-top: -140px;
  cursor: pointer;
}

.appAdd__mission__title__active {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #7a615a;
  margin: 0 auto;
  padding-bottom: 10px;
}

.appAdd__mission__instructions__active {
  display: block;
  color: red;
  margin: 0 auto;
  // font-family: Roboto;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #7a615a;
  text-align: center;
  padding-bottom: 20px;
  max-width: 220px;
}

.appAdd__mission__edit {
  color: #36d5f2;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  /*margin-top: 20px;*/
  cursor: pointer;
}

.missionCardAdd {
  height: 360px;
}

@media screen and (max-width: 550px) {
  .missionCardAdd {
    height: 250px;
  }
}

@media screen and (max-width: 650px) {
  .appAdd {
    width: 100%;
  }
}

.missionPointsTitle {
  font-size: 14px;
  // font-family: Roboto;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-top: 5px;
  font-weight: bold;
}

/* source: https://codepen.io/tutsplus/pen/WROvdG */

[tooltip] {
  position: relative;
  z-index: 999999;
}
/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none;
  font-size: 0.9em;
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}

[tooltip]::before {
  content: "";
  border: 5px solid transparent;
  z-index: 1001;
}

[tooltip]::after {
  content: attr(tooltip);
  // font-family: "Roboto";
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 10px 7px 10px;
  border-radius: 5px;
  box-shadow: 0 0.2em 0.3em rgba(0, 0, 0, 0.3);
  background: #7a615a;
  color: #fff;
  font-size: 12px;
  z-index: 1000;
}

/* Make the tooltips respond to hover */

[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

/* don't show empty tooltips */

[tooltip=""]::before,
[tooltip=""]::after {
  display: none !important;
}

/* FLOW: UP */

[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #7a615a;
}

[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}

[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 50%;
  -webkit-transform: translate(-50%, -0.5em);
  transform: translate(-50%, -0.5em);
}

/* FLOW: DOWN */

[tooltip][flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #d1d1d1;
}

[tooltip][flow^="down"]::after {
  top: calc(100% + 5px);
}

[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  left: 50%;
  -webkit-transform: translate(-50%, 0.5em);
  transform: translate(-50%, 0.5em);
}

/* FLOW: LEFT */

[tooltip][flow^="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #d1d1d1;
  left: calc(0em - 5px);
  -webkit-transform: translate(-0.5em, -50%);
  transform: translate(-0.5em, -50%);
}

[tooltip][flow^="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  -webkit-transform: translate(-0.5em, -50%);
  transform: translate(-0.5em, -50%);
}

/* FLOW: RIGHT */

[tooltip][flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #d1d1d1;
  right: calc(0em - 5px);
  -webkit-transform: translate(0.5em, -50%);
  transform: translate(0.5em, -50%);
}

[tooltip][flow^="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  -webkit-transform: translate(0.5em, -50%);
  transform: translate(0.5em, -50%);
  z-index: 1000;
}

/* KEYFRAMES */

@-webkit-keyframes tooltips-vert {
  to {
    opacity: 0.9;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-vert {
  to {
    opacity: 0.9;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}

@-webkit-keyframes tooltips-horz {
  to {
    opacity: 0.9;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: 0.9;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
}

/* FX All The Things */

[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  -webkit-animation: tooltips-vert 300ms ease-out forwards;
  animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
  -webkit-animation: tooltips-horz 300ms ease-out forwards;
  animation: tooltips-horz 300ms ease-out forwards;
}
/* ===================================================*/
.container {
  width: 70%;
  margin: 15rem auto;
  display: flex;
  flex-direction: row;
}
.container > a {
  display: block;
  width: 6rem;
  margin: 0 2rem;
  text-decoration: none;
  border-radius: 6rem;
  padding: 0.5rem 1.5rem;
  color: #fff;
  background: #27ae60;
  font-family: "Noto Sans Tc";
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  text-align: center;
  border: 5px dashed rgba(255, 255, 255, 0.3);
}
body {
  background: #7f8c8d;
}
.missionAddcard {
  height: 360px;
}

.activity_item {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  transition: all 200ms ease-in-out !important;
  background-color: #f5f5f5;
  border: none;

  &:active {
    opacity: 0.6;
  }
  &__box {
  }
  &__container {
    display: flex;

    align-items: center;
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02rem;
    white-space: nowrap;
    overflow: hidden;
    // text-overflow: ellipsis;
    color: #806157;
  }

  &__description {
    font-size: 14px;
    color: #806157;
    white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    margin-top: 5px;
  }

  &__amount {
    // position: absolute;
    top: 5px;
    // right: 15px;
    color: var(--main);
    font-weight: 600;
    font-size: 11px;
  }

  &__icon {
    margin-right: 15px;
    display: flex;
    align-items: center;

    img {
      width: 50px;
    }
  }
}
.reward {
  font-size: 30px;
  margin-top: 5px;
  color: #9e9e9e;
}
</style>
