var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"activity_item",on:{"click":function($event){return _vm.$emit('details')}}},[_c('div',{staticClass:"activity_item__container"},[_c('div',[_c('div',{staticClass:"activity_item__title",staticStyle:{"word-wrap":"break-word","width":"300px","display":"flex","justify-content":"space-between"}},[_c('span',{staticClass:"inline-block"},[_vm._v(_vm._s(_vm.activeTitle))]),_c('span',{staticClass:"inline-block"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"#01a4c0","d":"M12.6 12L8 7.4L9.4 6l6 6l-6 6L8 16.6l4.6-4.6Z"}})])])]),_c('div',{staticClass:"activity_item__description"},[_vm._v(" "+_vm._s(_vm.description)+" ")]),_c('div',{staticClass:"font-bold reward",style:({
          color:
            _vm.stages.length === 1 && _vm.stages[0].status !== 'pending_reward'
              ? _vm.stages[0].status === 'completed'
                ? '#56B897'
                : _vm.stages[0].status === 'failed'
                ? '#FE8585'
                : _vm.stages[0].status === 'expired'
                ? '#FE8585'
                : _vm.stages[0].status === 'in_progress'
                ? '#9E9E9E'
                : '9E9E9E'
              : '9E9E9E',
        })},[(_vm.type == 'wishlist')?_c('div',[_vm._v("Product Reward")]):_vm._e(),(_vm.type == 'points')?_c('div',[_vm._v(" "+_vm._s(_vm.activeSumPoints)+" Points ")]):_vm._e(),(_vm.type == 'money')?_c('div',[_vm._v("+ £"+_vm._s(_vm.activeSum))]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }